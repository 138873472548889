/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useCallback, useState, useEffect } from 'react'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Wrapper from '../components/layout/wrapper'
import Inner from '../components/layout/inner'
import { Stack } from 'raam'
import Feed from '../components/feed'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { PlayButton, Player } from '../components/video'
import queryString from 'query-string'
import useScrollPosition from '@react-hook/window-scroll'
import MetaData from '../components/metadata'

const Page = props => {
  const getFilterType = () => {
    const parsed = queryString.parse(props.location.search)
    const { filter } = parsed
    return filter
  }

  const scrollY = useScrollPosition(60 /*fps*/)
  const filterType = getFilterType()
  const [video, setVideo] = useState()
  const [heroStory, setHeroStory] = useState()
  const [playHeroVideo, setPlayHeroVideo] = useState()

  const data = useStaticQuery(graphql`
    query NewsPageQuery {
      allContentfulNews(sort: { fields: date, order: DESC }) {
        edges {
          node {
            hero {
              ...HeroFragment
            }
            date
            title
            slug
            video
            videoEmbed
            excerpt
            thumbnail {
              file {
                url
              }
              gatsbyImageData(layout: FIXED, width: 550, height: 550)
            }
            article {
              ... on ContentfulArticle {
                title
                main {
                  raw
                  references {
                    ...ArticleFragment
                  }
                }
              }
            }
            category {
              name
              id
              slug
            }
          }
        }
      }
    }
  `)

  const filter = useCallback(
    edges => {
      const categories = props.pageContext?.category
        ? edges.filter(
            x => x.node?.category?.slug === props.pageContext.category
          )
        : edges

      const filtered =
        filterType && ['video', 'article'].includes(filterType)
          ? categories.filter(x => {
              return filterType === 'video' ? x.node?.video : !x.node?.video
            })
          : categories
      return filtered
    },
    [props.pageContext.category, filterType]
  )

  const { edges } = data.allContentfulNews

  useEffect(() => {
    const filtered = filter(edges)
    if (!filtered.length) return
    setHeroStory(filtered[0])
  }, [edges, filter, setHeroStory])

  useEffect(() => {
    const scroll = props.location.state?.scrollY
    if (scroll >= 0) {
      window.scrollTo(0, parseInt(scroll))
    }
  }, [props.location.state, props.location.search])

  useEffect(() => {
    const parsed = queryString.parse(props.location.search)
    const { video } = parsed
    if (!video) {
      setVideo(false)
      return
    }
    const selected = edges?.filter(x => x.node.slug === video)
    if (!selected || !selected.length) return
    const node = selected[0].node
    setVideo(node.video ? node : false)
  }, [props.location.search])

  const linkState =
    props.location.state?.scrollY >= 0
      ? { scrollY: props.location.state?.scrollY }
      : scrollY
      ? { scrollY }
      : false

  return (
    <Layout {...props}>
      <MetaData title="News" />

      {heroStory && heroStory?.node?.hero && (
        <Hero
          data={[
            {
              ...heroStory.node.hero[0],
              buttonLabel: 'Read More',
              buttonUrl: `/news/${heroStory.node.slug}`,
              buttonComponent: heroStory.node.video ? (
                <PlayButton
                  as={Link}
                  to={`/news?video=${heroStory.node.slug}`}
                  state={linkState}
                />
              ) : null,
            },
          ]}
          variant="dark"
          centeredTitle={true}
        />
      )}

      <Stack gap={4} mb={12}>
        <Wrapper>
          <Inner>
            <Feed
              location={props.location}
              featured
              pagination
              itemsToShow={16}
              category={props.pageContext?.category}
              filterType={filterType}
              data={data}
              offset={1}
              scrollY={scrollY}
            />
          </Inner>
        </Wrapper>
      </Stack>

      {video && (
        <Player
          src={video.videoEmbed}
          title={video.title}
          article={video.article}
          url="/news"
          linkState={linkState}
        />
      )}
    </Layout>
  )
}

export default Page
